.w-form-done {
  display: flex;
  /* width: auto; */
  /* height: auto; */
  height: auto;
  width: auto;
  text-align: center;
  line-height: 10px;
  align-items: center;
  border-radius: 20px;
  font-size: 20px;
  line-height: 2;
  background-color: #1c2855;
  color: white;
}
