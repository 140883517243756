.calendar-container {
  position: relative;
  width: 100%; /* Full width of the screen */
  padding-bottom: 56.25%; /* This gives the iframe a 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  margin: 0 auto;
}

/* Make the iframe responsive */
.responsive-calendar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Optional: Adjust height for smaller screens */
@media (max-width: 768px) {
  .calendar-container {
    padding-bottom: 75%; /* Taller aspect ratio for smaller screens */
  }
}

@media (max-width: 480px) {
  .calendar-container {
    padding-bottom: 100%; /* Square aspect ratio on very small screens */
  }
}
